.manageuser-content {
    flex-flow: row;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

.manageuser-center {
    flex-flow: row;
    justify-content: space-between;
    width: 70%;
    display: flex;
}

.user-col1 {
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
    flex-direction: column;
    width: 30%;
    height: 100%;
    display: flex;
}

.inbox-top {
    border-bottom: 1px solid var(--line);
    flex: none;
    justify-content: space-between;
    width: 100%;
    padding: 15px 10px 15px 15px;
    display: flex;
}

.phonetop_left {
    align-items: center;
    display: flex;
}

.phoneheader_text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 120%;
    line-height: 1.2em;
}

.phonetop_right {
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.copylink_icon {
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
}

.user-search {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.search {
    align-items: center;
    margin-bottom: 0;
    display: flex;
}

input[type="search"] {
    -webkit-appearance: none;
}

.user--search-input {
    background-image: url("https://cdn.prod.website-files.com/677bd940823bc1c1d0d8502d/677bd940823bc1c1d0d85094_crm-option-search.svg");
    background-position: 10px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    height: 35px;
    margin-bottom: 0;
    padding-left: 40px;
    font-size: 90%;
}

.search-button.hide {
    display: none;
}

.inbox-contain {
    flex-flow: column;
    height: 72vh;
    padding: 5px;
    display: flex;
    overflow: auto;
}

.user-item {
    color: #333;
    cursor: pointer;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    display: flex;
}

.useritem_left {
    flex: none;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    display: flex;
}

.user-avatar {
    color: #fff;
    background-color: #bcbec0;
    border-radius: 100px;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-weight: 500;
    display: flex;
    position: relative;
}

.inboxtextcontent {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    display: flex;
}

.username {
    font-size: 85%;
    font-weight: 700;
    line-height: 1.2em;
}

.useritem_right {
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    display: flex;
}

.user-optiondiv {
    display: block;
    position: relative;
}

.template-options {
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    display: flex;
    position: static;
    inset: 5px 10px auto auto;
}

.user-col2 {
    box-shadow: none;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
    flex-direction: column;
    flex: 1;
    width: 30%;
    max-height: 85vh;
    margin-left: 15px;
    padding: 20px;
    display: flex;
    overflow: auto;
}

.userdetail-div {
    flex-flow: column;
    display: flex;
}

.userdetail-top {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    display: flex;
}

.userdetail-topleft {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.userdetail-avatar {
    color: #fff;
    background-color: #bcbec0;
    border-radius: 100px;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    margin-right: 10px;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    position: relative;
}

.btn-outlinegrey {
    color: gray;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 35px;
    padding: 10px 15px;
    font-size: 90%;
    font-weight: 600;
    text-decoration: none;
    display: flex;
}

.userdetail-topright {
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.phonetop_iconbtn {
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    box-shadow: -7px -3px 9px #ffffffb3, 1px 2px 8px -1px #0003;
}

.userdetails-row {
    border-top: 1px solid #e6e6e6;
    flex-flow: column;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
}

.userdetail-row-hd-div {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.userdetail-row-hd {
    color: #17103a;
    margin-top: 0;
    font-size: 100%;
    line-height: 1.2em;
}

.userdetails-row-content {
    flex-flow: wrap;
    place-content: flex-start;
    display: flex;
}

.userdetails-row-item {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 45%;
    margin-bottom: 20px;
    display: flex;
}

.p-style1 {
    margin-bottom: 0;
    font-size: 80%;
    line-height: 1.3em;
}

.p-style1.mb-5 {
    margin-bottom: 5px;
}

.p-style1.text-bold {
    font-weight: 600;
}

.userdetail-empty {
    flex-flow: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: none;
}

.userdetail-empty-icon {
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    display: flex;
}

.heading {
    color: #333c;
    font-size: 130%;
    font-weight: 600;
    line-height: 1.2em;
}

.popup-wrap {
    position: absolute;
}

.incoming-call {
    z-index: 10;
    background-color: #2a2a36;
    border: 1px solid #2a2a36;
    border-radius: 5px;
    flex-direction: row;
    justify-content: space-between;
    width: 250px;
    padding: 9px;
    display: none;
    position: fixed;
    inset: 70px 20px auto auto;
    box-shadow: 0 2px 5px #0003;
}

.incomingcall_left {
    color: #fff;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.notification_textcontents_top {
    align-items: center;
    margin-bottom: 3px;
    display: flex;
}

.notificationavatar {
    background-color: #2ec4c2;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    display: flex;
}

.avatar_image {
    border-radius: 100px;
}

.username {
    font-size: 85%;
    font-weight: 700;
    line-height: 1.2em;
}

.username.larger {
    color: #fff;
    font-size: 100%;
}

.notification_textcontents_bot {
    align-items: center;
    display: flex;
}

.previewtext {
    color: #333c;
    font-size: 80%;
    font-weight: 500;
    line-height: 1.2em;
}

.previewtext.larger.text-white {
    color: #fffc;
}

.previewtext.larger {
    font-size: 100%;
}

.notification_link_right {
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.notificationicon_callaccept {
    color: #333c;
    text-align: center;
    cursor: pointer;
    background-color: #7ac943;
    border-radius: 100px;
    flex-direction: column;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 6px;
    font-size: 23px;
    font-weight: 600;
    line-height: 1em;
    display: flex;
}

.notificationicon_calldecline {
    color: #333c;
    text-align: center;
    cursor: pointer;
    background-color: #ea4d3c;
    border-radius: 100px;
    flex-direction: column;
    flex: none;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin-left: 5px;
    padding: 6px;
    font-size: 23px;
    font-weight: 600;
    line-height: 1em;
    display: flex;
}

.createnewuser-popup {
    z-index: 50;
    background-color: #0000004d;
    justify-content: flex-end;
    align-items: stretch;
    display: none;
    position: fixed;
    inset: 0%;
}


@media screen and (min-width: 1920px) {
    .createnewuser-popup-main {
        width: 30%;
    }
}

.createnewuser-popup-main {
    background-color: #fff;
    flex-flow: column;
    width: 35%;
    display: flex;
}

.createnewuser-popup-top {
    border-bottom: 1px solid #e6e6e6;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    display: flex;
}

.popup_close {
    z-index: 60;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    inset: 10px 10px auto auto;
}

.createnewuser-popup-body {
    flex-flow: column;
    flex: 1;
    padding: 20px;
    display: flex;
    overflow: auto;
}

.createnewuser-popup-formrow {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
}

.formlabel-contain {
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    display: flex;
}

.p-style2 {
    margin-bottom: 0;
    font-size: 90%;
    line-height: 1.3em;
}

.forminput-contain {
    justify-content: flex-start;
    align-items: center;
    width: 65%;
    display: block;
}

.createnewuser-popup-group {
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
    padding-bottom: 20px;
}

.createnewuser-popup-bot {
    background-color: #fcfcfc;
    border-top: 1px solid #e6e6e6;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px 20px;
    display: flex;
}

.edituser-popup {
    z-index: 50;
    background-color: #0000004d;
    justify-content: flex-end;
    align-items: stretch;
    display: none;
    position: fixed;
    inset: 0%;
}

.userdetail-password-view {
    flex: none;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    margin-bottom: 5px;
    margin-right: 5px;
    display: flex;
}

.userdetail-passworddiv {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.addskills-contain {
    display: flex;
}

.btn-outline {
    border: 1px solid var(--new-violet);
    color: var(--new-violet);
    background-color: #fff;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 35px;
    padding: 10px 15px;
    font-size: 90%;
    font-weight: 500;
    text-decoration: none;
    display: flex;
}