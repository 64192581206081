body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.data-col-edit-input {
  order: none;
  outline: none;
  background-color: #eeeffe;
  width: 100px;
  max-width: 100px;
  min-width: 50px;
}

.margin-right-5 {
  margin-right: 5px;
}

.width-10 {
  width: 10px
}

.primary-btn {
  background-color: #6d42f8;
  color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: fit-content;
  padding: 10px 15px;
  font-size: 90%;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}